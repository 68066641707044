<template>
  <div class="flex w-full h-full">
    <div v-if="Object.keys(phones)" class="w-2/5 grid grid-cols-4 h-full" style="perspective: 1000px;">
      <div v-for="(phone, id) in phones" :key="id" class="flex justify-center items-center p-6">
        <div class="flex border border-1 border-black filter drop-shadow-md rounded-sm" :style="{
          width: `${(9/16)*100}px`,
          height: `100px`,
          overflow: 'hidden',
          padding: '5px 5px 5px 5px',
          transition: 'background-color 500ms linear',
          backgroundColor: phone.color,
          transform: `rotate(${Math.floor(phone.coords.yaw)}deg) rotate3d(1, 0, 0, ${Math.floor(phone.coords.pitch)}deg) rotate3d(0, 1, 0, ${Math.floor(phone.coords.roll)}deg)`
        }">{{ phone.name }}</div>
        <span class="focus-border">
          <i></i>
        </span>
      </div>
    </div>
    <div class="w-3/5">
      <QrCode />
    </div>
  </div>
</template>

<script>
const { io } = require("socket.io-client");
import QrCode from './QrCode.vue'
// import BarChart from './BarChart.vue'


export default {
  name: 'MainDisplay',
  components: {
    QrCode,
//  BarChart
  },
  data () {
    return {
      status: [],
      phones: {},
      data: [0, 0]
    }
  },
  mounted () {
    this.socket = io("wss://nua.jaredtilston.com:3000", {
      reconnectionDelayMax: 10000,
      autoConnect: true
    });

    this.socket.on('connected', (msg) => {
      console.log('Frontend connected: ', msg)
      this.status.unshift(`${msg.id} connected`)
      this.phones[msg.id] = {
        name: msg.id,
        color: msg.color,
        coords: {}
      };
    })

    this.socket.on('setData', (msg) => {
      console.log(`Set data (${Date.now()})`, msg)
      if (Object.keys(this.phones).includes(msg.id)) {
        let key = Object.keys(msg).pop();
        this.phones[msg.id][key] = msg[key];
      }
      console.log(msg)
    })

    this.socket.on('disconnected', (msg) => {
      console.log('Connected: ', msg)
      this.status.unshift(`${msg} disconnected`)
      delete this.phones[msg]
    })
  },
  beforeUnmount () {
    this.socket.disconnect();
  },
  methods: {
    resetPoll () {
      this.data = [0,0]
    }
  }
}
</script>

<style scoped>
</style>