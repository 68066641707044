<template>
  <div class="w-full" ref="qr">
    <qrcode-vue foreground="#374151" :size="qrSize" :value="qrcode"></qrcode-vue>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'QrCode',
  components: {
    QrcodeVue,
  },
  data () {
    return {
      qrcode: `https://nua.jaredtilston.com/controller`,
      qrSize: 100
    }
  },
  mounted () {
    this.qrSize = this.$refs.qr.clientWidth
  },
  updated () {
  }
}
</script>