import { createWebHistory, createRouter } from "vue-router";
import LandscapeDisplay from "../components/LandscapeDisplay.vue";
import PortraitDisplay from "../components/PortraitDisplay.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: LandscapeDisplay,
  },
  {
    path: "/display",
    name: "Display",
    component: LandscapeDisplay,
  },
  {
    path: "/controller",
    name: "PortraitDisplay",
    component: PortraitDisplay,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;