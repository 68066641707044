<template>
  <div v-if="error" class="bg-red-400 text-white">{{ error }}</div>
  <div class="">Hello...</div>
  <div v-if="!perms" class="absolute inset-0 flex flex-col justify-center items-center filter opacity-80 bg-black">
    <div class="w-1/2 text-center text-white">
      <p>Looks like you have an IPhone :spew-emoji:</p>
      <p class="mt-6">Please tap below to allow me to see your phone's orientation</p>
    </div>
    <button class="mt-6 p-3 rounded-md bg-gray-700 text-white" @click="requestPermission">Enable fun!</button>
  </div>
</template>

<script>
const { io } = require("socket.io-client");

// import { Chrome } from '@ckpack/vue-color';

export default {
  name: 'PhoneController',
  components: {
    // Chrome
  },
  data () {
    return {
      error: null,
      name: null,
      color: '#FFFFFF',
      perms: false
    }
  },
  mounted () {
    this.socket = io("wss://nua.jaredtilston.com:3000", {
      reconnectionDelayMax: 10000,
      autoConnect: true
    })

    this.socket.on('connect', () => {
      this.name = this.socket.id
    })

    const genRanHex = size => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('')

    this.color = `#${genRanHex(6)}`

    this.socket.emit('controllerConnect', { color: this.color })

    if (typeof DeviceOrientationEvent.requestPermission === 'function') {
      this.requestPermission()
    } else {
      this.perms = true
      if (window.DeviceOrientationEvent) {
        this.error = null
        window.addEventListener('deviceorientation', this.motionHandler, false);
      } else {
        this.error = "Your device doesn't allow motion detection"
      }
    }

  },
  beforeUnmount () {
    this.socket.disconnect();
  },
  methods: {
    requestPermission () {
        DeviceOrientationEvent.requestPermission()
        .then(response => {
          if (response == 'granted') {
            window.addEventListener('deviceorientation', this.motionHandler)
            this.perms = true
          }
        })
        .catch(console.error)
    },
    motionHandler (e) {
      var protoEvent = {pitch: 0, roll: 0, yaw: 0}
          protoEvent.yaw = -e.alpha
          protoEvent.pitch = -(e.beta-90)
          protoEvent.roll = e.gamma

      this.socket.emit('setData', { coords: protoEvent })
    },
    setColor () {
      this.socket.emit('setData', { color: this.color.hex8 })
    },
    setName () {
      this.socket.emit('setData', { name: this.name })
    },
    selectName () {
      this.$refs.name.select()
    },
    answer (response) {
      console.log({ answer: response })
      this.socket.emit('pollAnswer', { answer: response })
    }
  }
}
</script>