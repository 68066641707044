import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './main.css'
import { TroisJSVuePlugin } from 'troisjs';

const app = createApp(App)
			.use(router)
			.use(TroisJSVuePlugin)

app.mount('#app')